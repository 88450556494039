<script>
    import Card from '../components/Card.svelte';
</script>

<div class="w-full h-full flex justify-center items-center flex-col flex-1 my-20">
    <Card class="w-4/5 md:w-3/5">
        <h1 class="text-center font-black font-akshar text-bright-gray-500 text-4xl py-2">Terms Of Service</h1>
        <p>
            The terms of service outline the rules for accessing and using Shrtu, including any content functionality and
            services offered by the site.
            <br /><br />
            Before using the site, make sure to carefully read the terms of service. This page explains the terms of use
            for Shrtu, and by using the site, users are bound by these terms.
            <br /><br />
            Shrtu, the URL shortener service, is available to users of all countries and ages. However, it is prohibited
            to create shortened URLs that redirect to:
            <br />
            <span class="ml-4">• Content spreading phishing, malware, or viruses</span><br/>
            <span class="ml-4">• Abusive or suspicious content</span><br/>
            <span class="ml-4">• Pornographic or sexual content</span><br/>
            <span class="ml-4">• Violent or prejudiced content</span><br/>
            <span class="ml-4">• Material that may infringe on other people’s rights</span><br/>
            <span class="ml-4">• Disgusting, explicit, or offensive content causing discomfort to users</span><br/>
            <span class="ml-4">• Pop-ups, scripts, and malicious code</span><br/>
            <br/>
            Any shortened URL leading to a landing page violating the above conditions may be deleted. If we receive
            reports of spam or notice abusive behavior violating our terms of service, the shortened URL will be removed
            without notice.
        </p>
        <h1 class="font-black font-akshar text-bright-gray-500 text-4xl py-2 left mt-6">Disclamer</h1>
        <p>
            The free service provided by Shrtu has limitations, so we cannot guarantee uninterrupted, secure, or
            error-free site or services. Neither employees nor owners will be held responsible for any errors or
            omissions on the site or any resulting damages.
        </p>
        <h1 class="font-black font-akshar text-bright-gray-500 text-4xl py-2 mt-6">User’s
            Responsibility</h1>
        <p>
            When you use this site, you take personal responsibility for the outcomes of your actions. You agree to bear
            full responsibility for any damages or injuries resulting from using the services available on this site or
            other resources linked from here. Before acting on any suggestions or recommendations found on this site,
            exercise your judgment and conduct due diligence.
        </p>
        <h1 class="font-black font-akshar text-bright-gray-500 text-4xl py-2 mt-6">Errors and Omissions</h1>
        <p>
            While this site offers a free service, it does not guarantee accuracy, completeness, or up-to-date
            information. Although we’ve taken steps to ensure accuracy, we cannot assert that the site is error-free.
        </p>
        <h1 class="font-black font-akshar text-bright-gray-500 text-4xl py-2 mt-6">Limitation of
            Liability</h1>
        <p>
            By using this site, you release the Site from any liability or loss that you or any associated person or
            entity may experience due to the information on this Site or the resources accessed from it. The Site will
            not be liable for any damages, including direct, indirect, special, incidental, equitable, or consequential
            losses resulting from site use.
            <br /><br />
            The information, software, products, and services available through the Site may contain inaccuracies or
            typographical errors. Developers may make improvements or changes to the Site at any time, with periodic
            updates to the information provided.
            <br /><br />
            The Site and its developers do not guarantee the suitability, reliability, availability, timeliness, or
            accuracy of the information, software, products, and services on the Site for any purpose. All such
            offerings are provided “As is,” without warranty or condition, to the maximum extent allowed by applicable
            law. The Site and its developers disclaim all implied warranties or conditions, including merchantability,
            fitness for a specific purpose, title, and non-infringement.
        </p>
        <h1 class="font-black font-akshar text-bright-gray-500 text-4xl py-2 mt-6">Terms Updates</h1>
        <p>
            Shrtu reserves the right to update or modify these terms of use at any time. The most current version will
            always be accessible on this page.
        </p>
    </Card>
</div>
