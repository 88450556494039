import axios from 'axios';
export var TakedownStatus;
(function (TakedownStatus) {
    TakedownStatus[TakedownStatus["PENDING"] = 0] = "PENDING";
    TakedownStatus[TakedownStatus["PROCESSING"] = 1] = "PROCESSING";
    TakedownStatus[TakedownStatus["ACCEPTED"] = 2] = "ACCEPTED";
    TakedownStatus[TakedownStatus["REJECTED"] = 3] = "REJECTED";
})(TakedownStatus || (TakedownStatus = {}));
class Services {
}
Services.shortenUrl = (destination, passphrase) => {
    return axios.post('shorten/short', { url: destination, passphrase: passphrase !== null && passphrase !== void 0 ? passphrase : null });
};
Services.unlockUrl = (code, passphrase) => {
    return axios.post('shorten/unlock', { code, passphrase });
};
Services.reportUrl = (code, reason, passphrase) => {
    return axios.post('takedown/report', { code, reason, passphrase });
};
Services.listReports = () => {
    return axios.get(`takedown/list`);
};
export default Services;
